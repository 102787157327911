import type { WatchQueryFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import type { QueryResult } from '@apollo/client/react';
import type { FeaturedWidgetInstance } from '@aurora/shared-client/components/context/FeaturedWidgetContext/FeaturedWidgetContext';
import FeaturedWidgetContext from '@aurora/shared-client/components/context/FeaturedWidgetContext/FeaturedWidgetContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import { useContext } from 'react';
import type {
  FeaturedPlacesWidgetQuery,
  FeaturedPlacesWidgetQueryVariables,
  ProvisionedFeaturedPlacesQuery,
  ProvisionedFeaturedPlacesQueryVariables
} from '../../../../types/graphql-types';
import provisionalPlacesQuery from './ProvisionedFeaturedPlaces.query.graphql';
import placesQuery from './FeaturedPlacesWidget.query.graphql';

function getCoreNodesOrderedByUser(
  data: ProvisionedFeaturedPlacesQuery,
  featuredItemIds: string[]
) {
  const coreNodeEdges = [];
  data?.coreNodes.edges.map(edge => coreNodeEdges.push(Object.assign({}, edge)));
  return coreNodeEdges?.sort(function sortFunction(a, b) {
    return featuredItemIds.indexOf(a.node.id) - featuredItemIds.indexOf(b.node.id);
  });
}

/**
 * Helper for using the FeaturedPlacesWidget query
 *
 * @param queryVariables variables used by the FeaturedPlacesWidget query
 * @param skip whether to skip the featured content widget query
 * @param fetchPolicy the fetch policy for the query
 * @param onCompleted callback function invoked when
 */
export default function useFeaturedPlacesWidget(
  queryVariables: FeaturedPlacesWidgetQueryVariables,
  skip = false,
  fetchPolicy: WatchQueryFetchPolicy = 'cache-first',
  onCompleted?: (data: FeaturedPlacesWidgetQuery | ProvisionedFeaturedPlacesQuery) => void
): QueryResult<FeaturedPlacesWidgetQuery, FeaturedPlacesWidgetQueryVariables> {
  const {
    publicConfig: { auroraFeaturedWidgetsEnabled }
  } = useContext(TenantContext);
  const { featuredWidgetInstances } = useContext(FeaturedWidgetContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { instanceId, quiltId, coreNodeId, first, ...restQueryVariables } = queryVariables;

  const sessionInstance: FeaturedWidgetInstance = featuredWidgetInstances?.find(
    featuredWidgetInstance => featuredWidgetInstance.instanceId === instanceId
  );
  const sessionInstanceHasPlaces: boolean = sessionInstance?.featuredItemIds?.length > 0;
  const skipQuery: boolean = skip || !auroraFeaturedWidgetsEnabled || !!sessionInstance;
  const skipProvisionalQuery: boolean =
    skip ||
    !auroraFeaturedWidgetsEnabled ||
    !sessionInstance ||
    // API throws an error when using the place id constraint and providing an empty array of ids, so skip
    (sessionInstance && !sessionInstanceHasPlaces);

  // retrieves the places and last modified information if the widget is already stored
  const featuredPlacesWidgetQueryResult = useQueryWithTracing<
    FeaturedPlacesWidgetQuery,
    FeaturedPlacesWidgetQueryVariables
  >(module, placesQuery, {
    variables: queryVariables,
    skip: skipQuery,
    fetchPolicy,
    onCompleted
  });

  // if the widget has been added during the current page editor session queries for the selected places directly
  const provisionedPlacesQueryResult = useQueryWithTracing<
    ProvisionedFeaturedPlacesQuery,
    ProvisionedFeaturedPlacesQueryVariables
  >(module, provisionalPlacesQuery, {
    variables: {
      ...restQueryVariables,
      first,
      placeIds: sessionInstance?.featuredItemIds.slice(0, first)
    },
    skip: skipProvisionalQuery,
    fetchPolicy,
    onCompleted: data => {
      const result: ProvisionedFeaturedPlacesQuery = {
        ...data,
        coreNodes: {
          ...data.coreNodes,
          edges: getCoreNodesOrderedByUser(data, sessionInstance?.featuredItemIds)
        }
      };
      if (onCompleted) {
        onCompleted(result);
      }
    }
  });

  if (!sessionInstance) {
    return featuredPlacesWidgetQueryResult;
  } else {
    const { loading, error, data } = provisionedPlacesQueryResult;

    // fallbacks provided in the event the widget was added during the current session but the query is skipped due
    //  to no places yet being associated
    return {
      ...featuredPlacesWidgetQueryResult,
      loading: loading || false,
      error: error || null,
      data: {
        featuredPlacesWidget: {
          coreNodes: data?.coreNodes
            ? {
                ...data.coreNodes,
                edges: getCoreNodesOrderedByUser(data, sessionInstance?.featuredItemIds),
                pageInfo: {
                  ...data.coreNodes.pageInfo,
                  hasNextPage: sessionInstance?.featuredItemIds.length > first
                }
              }
            : {
                totalCount: 0,
                edges: [],
                pageInfo: undefined
              }
        }
      }
    };
  }
}
